@font-face {
  font-family: 'CustomFont';
  src: url('../../assets/MaoKenZhuYuanTi-MaokenZhuyuanTi-2.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

.container {
  padding: 3rem 5rem;
  background-color: #eee9ff;
  min-height: 100vh;
  font-family: 'CustomFont', sans-serif;
}
.header {
  color: #333;
  margin-bottom: 20px;
}
.header h1 {
  font-size: 18px;
  font-weight: normal;
  font-family: 'CustomFont', sans-serif;
}
.progressSteps {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0;
  padding: 0 20px;
}
.stepItem {
  display: flex;
  align-items: center;
  flex: 1;
  position: relative;
}

.timeline {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 40px 0;
  position: relative;
}

/* 添加连接线 */
.timeline::before {
  content: '';
  position: absolute;
  top: 15px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #000000;
  z-index: 1;
}

.timelineStep {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 2;
  cursor: pointer;
  flex: 1;
}

.stepCircle {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.stepNumber {
  font-size: 16px;
  font-weight: 500;
  color: #999;
}

.stepNumber.current {
  color: #1890ff;
}

.stepNumber.done {
  color: #52c41a;
}

.stepContent {
  margin-left: 12px;
  min-width: 80px;
  animation: fadeIn 0.3s ease-in-out;
}

.stepContent1 {
  margin-left: 12px;
  min-width: 80px;
  animation: fadeIn 0.3s ease-in-out;
}

.stepTitle {
  font-size: 14px;
  color: #999;
  margin-top: 4px;
  font-family: 'CustomFont', sans-serif;
}

.stepTitle.current {
  color: #1890ff;
  font-weight: 500;
}

.stepTitle.done {
  color: #52c41a;
  font-weight: 500;
}

.stepLine {
  flex: 1;
  height: 2px;
  background-color: #e8e8e8;
  margin: 0 8px;
}

.stepLine.current {
  background: linear-gradient(to right, #52c41a 50%, #f0f0f0 50%);
}

.stepLine.done {
  background: #52c41a;
}

.stepCircle.current {
  background: #e6f7ff;
  border: 2px solid #1890ff;
}

.stepCircle.done {
  background: #f6ffed;
  border: 2px solid #52c41a;
}

/* 激活状态 */
.timelineStep.active .stepNumber {
  background-color: #409EFF;
  border-color: #409EFF;
  color: white;
}

.timelineStep.active .stepTitle {
  color: #409EFF;
}

/* 完成状态的样式 */
.timelineStep.completed .stepNumber {
  background-color: #67C23A;
  border-color: #67C23A;
  color: white;
}

.timelineStep.completed .stepTitle {
  color: #67C23A;
}

/* 内容区域 */
.content {
  margin-top: 40px;
  padding: 20px;
  border: 1px solid #E4E7ED;
  border-radius: 4px;
  min-height: 200px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* 按钮组样式 */
.buttonGroup {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  margin-top: 20px;
}

.button {
  padding: 10px 20px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  font-size: 14px;
  transition: all 0.3s;
}

.prevButton {
  background-color: #909399;
  color: white;
}

.prevButton:hover {
  background-color: #82848a;
}

.nextButton {
  background-color: #409EFF;
  color: white;
}

.nextButton:hover {
  background-color: #337ecc;
} 

.uploadSection {
  margin-bottom: 20px;
}

.hiddenInput {
  display: none;
}

.uploadButton {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  background-color: #f5f5f5;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 15px;
}

.uploadButton:hover {
  background: #40a9ff;
}

.uploadIcon {
  font-size: 18px;
}



.designContainer {
  display: flex;
  gap: 20px;
  width: 100%;
}

.leftSection,
.rightSection {
  flex: 1;
}

.sectionTitle {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #333;
}

.imagePreview {
  width: 100%;
  height: 200px;
  border: 1px solid #ddd;
  border-radius: 4px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
}

.previewImg {
  max-width: 100%;
  max-height: 100%;
}

.placeholderText {
  color: #999;
}

.promptInput {
  width: 100%;
  min-height: 20px;
  padding: 12px 0 12px 0;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 14px;
  resize: vertical;
  margin-bottom: 15px;
}

.generateButton {
  width: 100%;
  padding: 12px 24px;
  background-color: #52c41a;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.generateButton:hover {
  background-color: #73d13d;
}

.generatedResults {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  min-height: 400px;
  background-color: #fafafa;
  border-radius: 8px;
  padding: 20px;
}

.aiContainer {
  background: white;
  border-radius: 8px;
  padding: 20px;
}

.tabButtons {
  margin-bottom: 20px;
}

.tabButton {
  padding: 8px 16px;
  margin-right: 10px;
  border: none;
  background: none;
  cursor: pointer;
}

.tabButton.active {
  background: #40b347;
  color: white;
  border-radius: 4px;
}

.aiContent {
  display: flex;
  gap: 2rem;
  width: 100%;
}

.leftPanel, .rightPanel {
  flex: 1; /* Prevents flex items from overflowing */
}

.gridContainer {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(4, 1fr);
  gap: 10px;
}

.gridItem {
  aspect-ratio: 1;
  background: #e6e6fa;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  cursor: move;
  transition: all 0.2s;
}

.gridItem:hover {
  transform: scale(1.05);
}

.gridItem:nth-child(6),
.gridItem:nth-child(7),
.gridItem:nth-child(8) {
  background: #ffa500;
}

.gridItem:nth-child(11),
.gridItem:nth-child(12),
.gridItem:nth-child(13),
.gridItem:nth-child(14),
.gridItem:nth-child(15) {
  background: #90ee90;
}

.traitCategory {
  margin-bottom: 15px;
}

.traitTitle {
  margin-bottom: 10px;
  font-weight: bold;
}

.traitButtons {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.traitButton {
  width: 32px;
  height: 32px;
  border-radius: 4px;
  border: none;
  background: #b19cd9;
  color: white;
  cursor: move;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s;
}

.traitButton:hover {
  transform: scale(1.05);
}

.previewContainer {
  display: flex;
  gap: 20px;
  padding: 20px;
}

.previewSection {
  flex: 1;
}

.sectionTitle {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 1rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #e0e0e0;
}

.interactionPreview,
.dialoguePreview {
  flex: 1;
  background-color: #f5f5f5;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 400px;
}

.previewPlaceholder {
  color: #666;
  text-align: center;
}

.orderContainer {
  display: flex;
  gap: 24px;
  padding: 20px;
}

.orderLeftSection,
.orderRightSection {
  flex: 1;
}

.productImages {
  display: flex;
  gap: 1rem;
  margin-bottom: 2rem;
}

.productImage {
  width: 200px;
  height: 200px;
  object-fit: cover;
  border-radius: 8px;
}

.productDetails {
  padding: 1rem;
  background-color: #f5f5f5;
  border-radius: 8px;
}

.detailRow {
  display: flex;
  justify-content: space-between;
  margin: 0.5rem 0;
}

.orderRightSection {
  flex: 1;
}

.orderForm {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.formRow {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.addressButton {
  padding: 0.5rem 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  background: none;
  cursor: pointer;
}

.orderFooter {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  padding: 1rem 2rem;
  border-top: 1px solid #eee;
}

.cancelButton {
  background-color: #f5f5f5;
  color: #666;
  border: none;
  padding: 8px 24px;
  border-radius: 20px;
  cursor: pointer;
  font-size: 14px;
}

.cancelButton:hover {
  background-color: #e8e8e8;
}

.payButton {
  background-color: #1890ff;
  color: white;
  border: none;
  padding: 8px 24px;
  border-radius: 20px;
  cursor: pointer;
  font-size: 14px;
}

.payButton:hover {
  background-color: #40a9ff;
}

.generatedImg {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.panelsContainer {
  display: flex;
  gap: 20px;
  width: 100%;
}

/* Mobile styles */
@media screen and (max-width: 768px) {
  .container {
    padding: 3px 5px;
    background-color: #eee9ff;
    min-height: 100vh;
    font-family: 'CustomFont', sans-serif;
  }

  /* 内容区域 */
.content {
  margin-top: 10px;
  padding: 5px;
  border: 1px solid #E4E7ED;
  border-radius: 4px;
  min-height: 200px;
}

.stepContent {
  margin-left: 0px;
  min-width: 80px;
  animation: fadeIn 0.3s ease-in-out;
}

.stepContent1 {
  display: none;
  margin-left: 0px;
  min-width: 80px;
  animation: fadeIn 0.3s ease-in-out;
}

.orderContainer {
  display: flex;
  gap: 2px;
  padding: 2px;
}
  
  .progressSteps {
    position: fixed;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    flex-direction: column;
    background: rgba(255, 255, 255, 0.9);
    padding: 10px;
    border-radius: 8px 0 0 8px;
    box-shadow: -2px 0 10px rgba(0, 0, 0, 0.1);
    z-index: 100;
  }

  .stepItem {
    margin: 5px 0;
  }

  .stepLine {
    height: 20px;
    width: 2px;
    margin: 5px auto;
  }

  .stepTitle {
    font-size: 12px;
    white-space: nowrap;
  }

  .stepCircle {
    width: 24px;
    height: 24px;
  }

  .stepNumber {
    font-size: 12px;
  }

  .designContainer {
    flex-direction: column;
  }

  .leftSection,
  .rightSection {
    width: 100%;
  }
}

/* 移动端样式 */
@media (max-width: 768px) {
  .orderContainer {
    flex-direction: column;
    gap: 20px;
  }
  .panelsContainer {
    flex-direction: column;
  }
  .leftPanel, .rightPanel {
    width: 100%;
  }
  .gridContainer {
    max-width: 100%;
    margin: 0 auto;
  }
  .modelBank {
    margin-top: 20px;
  }
  .orderLeftSection,
  .orderRightSection {
    width: 100%;
  }

  .previewContainer {
    flex-direction: column;
  }

  .previewSection {
    width: 100%;
  }
}

.modelBank {
  width: 100%;
}