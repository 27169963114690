@font-face {
  font-family: 'CustomFont';
  src: url('../../assets/MaoKenZhuYuanTi-MaokenZhuyuanTi-2.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

.container {
  padding: 3rem 5rem;
  margin: 0 auto;
  font-family: 'CustomFont', sans-serif;
}

@media screen and (max-width: 768px) {
  .container {
    padding: 1rem;
  }
}

.title {
  text-align: center;
  margin-bottom: 2rem;
  font-size: 2rem;
  color: #333;
}

.teamGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2rem;
  margin-bottom: 2rem;
}

.teamCard {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  cursor: pointer;
}

.imageContainer {
  display: flex;
  overflow: hidden;
  white-space: nowrap;
}

.teamImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.contentContainer {
  background-color: var(--background-color); /* 使用与页面相同的背景色变量 */
}

.middleSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
  color: #6a5caf;
}

.productName {
  font-size: 16px;
  font-weight: bold;
  margin: 0;
  font-family: 'CustomFont', sans-serif;
}

.price {
  font-size: 18px;
  font-weight: bold;
  font-family: 'CustomFont', sans-serif;
}

.bottomSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0;
  background-color: #eee9ff;
  color: #6a5caf;
}

.description {
  color: #6a5caf;
  font-size: 14px;
  font-family: 'CustomFont', sans-serif;
  max-width: 100%;
}

.groupInfo {
  font-size: 14px;
  font-family: 'CustomFont', sans-serif;
  background-color: #6a5caf;
  color: #fff;
  border-radius: 5px;
  padding: 5px;
}

.teamCard:hover {
  transform: translateY(-2px);
}

.teamCard:focus {
  outline: none;
}

.teamTitle {
  margin: 0 0 1rem 0;
  color: #333;
  font-size: 1.5rem;
}

.teamInfo {
  margin-bottom: 1rem;
}

.teamInfo p {
  margin: 0.5rem 0;
  color: #666;
}

.joinButton {
  width: 100%;
  padding: 0.8rem;
  background-color: #1890ff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.2s;
}

.joinButton:hover {
  background-color: #40a9ff;
}

.createTeamButton {
  display: block;
  margin: 2rem auto;
  padding: 1rem 2rem;
  background-color: #52c41a;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1.1rem;
  transition: background-color 0.2s;
}

.createTeamButton:hover {
  background-color: #73d13d;
}

.heroSection {
  width: 100%;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.imageRow {
  display: flex;
  overflow: hidden;
  position: relative;
}

.heroImage {
  min-width: 200%;
  object-fit: cover;
}

.floatingText {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  cursor: pointer;
  padding: 10px;
  background: #5d5ee2;
  border-radius: 4px;
  font-family: 'CustomFont', sans-serif;
}

.tabContainer {
  display: flex;
  margin: 20px 0;
  background-color: #fff0c5; /* 浅黄色背景 */
}

.tabs {
  display: flex;
  gap: 1px;
}

.tabButton {
  flex: 1;
  padding: 12px 24px;
  border: none;
  background: transparent;
  cursor: pointer;
  font-size: 16px;
  color: #666;
  transition: all 0.3s ease;
  position: relative;
  font-family: 'CustomFont', sans-serif;
}

.tabButton.active {
  color: #FF9800; /* 橙黄色文字 */
  font-weight: bold;
}

.tabButton.active::after {
  content: '';
  position: absolute;
  bottom: -4px;
  left: 50%;
  transform: translateX(-50%);
  width: 40%;
  height: 3px;
  background-color: #FF9800; /* 橙黄色下划线 */
  border-radius: 2px;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modalContent {
  background-color: #f5f0ff;
  width: 80%;
  max-width: 600px;
  border-radius: 12px;
  padding: 24px;
  font-family: 'CustomFont', sans-serif;
}

.modalHeader {
  margin-bottom: 24px;
}

.modalHeader h2 {
  color: #6b4fbb;
  font-size: 24px;
  margin-bottom: 8px;
}

.subtitle {
  color: #666;
  font-size: 14px;
}

.closeButton {
  position: absolute;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #666;
}

.productTypes {
  margin-bottom: 24px;
}

.checkboxList {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.checkboxItem {
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: #6b4fbb;
  color: white;
  padding: 8px 16px;
  border-radius: 6px;
  cursor: pointer;
}

.checkboxItem input[type="checkbox"] {
  accent-color: #ff69b4;
  width: 18px;
  height: 18px;
}

.tagSection {
  margin-top: 24px;
}

.tagInput {
  display: flex;
  gap: 8px;
  margin-bottom: 16px;
}

.tagInput input {
  flex: 1;
  padding: 8px 16px;
  border: 2px solid #6b4fbb;
  border-radius: 6px;
  background-color: #fff;
}

.tagInput button {
  background-color: #6b4fbb;
  color: white;
  border: none;
  padding: 8px 24px;
  border-radius: 6px;
  cursor: pointer;
}

.tagList {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.tag {
  background-color: #ff9dcd;
  color: white;
  padding: 4px 12px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  gap: 4px;
}

.removeTag {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  padding: 0 4px;
}

.modalFooter {
  margin-top: 24px;
  display: flex;
  justify-content: flex-end;
  gap: 12px;
}

.confirmButton {
  background-color: #ffd700;
  border: none;
  padding: 8px 24px;
  border-radius: 6px;
  cursor: pointer;
  color: #333;
}

.cancelButton {
  background-color: #9385ae;
  border: none;
  padding: 8px 24px;
  border-radius: 6px;
  cursor: pointer;
  color: white;
}

.pageTitle h1 {
  font-size: 20px;
  font-weight: 400;
  font-family: 'CustomFont', sans-serif;
}

.separator {
  margin: 0 8px;
}

.darkBackground {
  background-color: #eee9ff;
}

.categoryTabs {
  display: flex;
  gap: 1px;
  margin: 20px 0;
  background-color: #cfb8fe;
  overflow: hidden;
}

.categoryTab {
  flex: 1;
  padding: 12px 24px;
  border: none;
  background: #cfb8fe;
  cursor: pointer;
  font-size: 16px;
  transition: all 0.3s ease;
  font-family: 'CustomFont', sans-serif;
}

.categoryTab.active {
  background: #6a5caf; /* 紫色背景 */
  color: white;
}

.categoryTabSec {
  flex: 1;
  padding: 12px 24px;
  border: none;
  background: #fff0c5;
  cursor: pointer;
  font-size: 16px;
  transition: all 0.3s ease;
  font-family: 'CustomFont', sans-serif;
  color: #af9dd3;
}

.categoryTabSec.active {
  background: #fff0c5; /* 紫色背景 */
  color: #fdacf1;
}

.modalBodyContainer {
  display: flex;
  gap: 2rem;
  justify-content: space-between;
}

.productTypesSection {
  flex: 1;
  padding-right: 1rem;
  border-right: 1px solid #ddd;
}

.tagSection {
  flex: 1;
  padding-left: 1rem;
}

.paginationContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1px;
  margin: 20px 0;
}

.paginationButton {
  padding: 8px 16px;
  border: 1px solid #fff;
  background-color: white;
  cursor: pointer;
  border-radius: 4px;
  transition: all 0.3s ease;
  color: #6a5caf;
}

.paginationButton:hover {
  background-color: #f0f0f0;
}

.paginationButton.active {
  background-color: #6a5caf;
  color: white;
  border-color: #6a5caf;
}

.scrollRight {
  animation: scrollRight 20s linear infinite;
}

.scrollLeft {
  animation: scrollLeft 20s linear infinite;
}

@keyframes scrollRight {
  0% {
    transform: translateX(-50%);
  }
  100% {
    transform: translateX(-150%);
  }
}

@keyframes scrollLeft {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
} 