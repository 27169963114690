.container {
  padding: 2rem;
  max-width: 800px;
  margin: 0 auto;
  background-color: #fff8f0;
}

.title {
  text-align: center;
  margin-bottom: 1rem;
  color: #d4363d;
  font-size: 2rem;
}

.description {
  text-align: center;
  color: #666;
  margin-bottom: 2rem;
}

.inputSection {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-bottom: 2rem;
}

.inputGroup {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.inputGroup label {
  color: #333;
  font-weight: 500;
}

.input, .textarea {
  width: 100%;
  padding: 0.8rem;
  border: 2px solid #ffb74d;
  border-radius: 8px;
  font-size: 1rem;
  background-color: #fff;
}

.textarea {
  resize: vertical;
}

.generateButton {
  padding: 1rem 2rem;
  background-color: #d4363d;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 1.1rem;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 1rem;
}

.generateButton:hover {
  background-color: #b71c1c;
}

.generateButton:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.loadingSection {
  text-align: center;
  margin: 2rem 0;
}

.spinner {
  width: 40px;
  height: 40px;
  border: 4px solid #fff0e5;
  border-top: 4px solid #d4363d;
  border-radius: 50%;
  margin: 0 auto 1rem;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.resultSection {
  margin-top: 2rem;
  text-align: center;
}

.generatedImage {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.shareSection {
  margin-top: 1rem;
}

.shareText {
  color: #666;
  font-size: 0.9rem;
}

/* 添加一些中国传统风格的装饰元素 */
.container::before,
.container::after {
  content: '福';
  position: absolute;
  color: #d4363d;
  opacity: 0.1;
  font-size: 100px;
  font-family: "STKaiti", "楷体", serif;
  z-index: -1;
}

.container::before {
  top: 20px;
  left: 20px;
  transform: rotate(-15deg);
}

.container::after {
  bottom: 20px;
  right: 20px;
  transform: rotate(15deg);
} 