.container {
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.header {
  margin-bottom: 2rem;
}

.title {
  text-align: center;
  color: #333;
  margin-bottom: 1.5rem;
}

.searchBar {
  max-width: 600px;
  margin: 0 auto;
}

.searchInput {
  width: 100%;
  padding: 12px 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 16px;
  outline: none;
  transition: border-color 0.3s;
}

.searchInput:focus {
  border-color: #1890ff;
}

.appGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  padding: 20px;
}

.appCard {
  background: white;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
  overflow: hidden;
}

.appCard:hover {
  transform: translateY(-5px);
}

.appContent {
  display: flex;
  padding: 15px;
  align-items: center;
  justify-content: space-between;
}

.appInfo {
  flex: 1;
  margin-right: 15px;
}

.appTitle {
  margin: 0 0 8px 0;
  font-size: 18px;
  color: #333;
}

.appDescription {
  margin: 0;
  font-size: 14px;
  color: #666;
}

.appIcon {
  width: 60px;
  height: 60px;
  border-radius: 8px;
  object-fit: cover;
}

/* 移动端适配 */
@media (max-width: 768px) {

  .container {
    padding: 5px;
    max-width: 1200px;
    margin: 0 auto;
  }

  .appGrid {
    grid-template-columns: 1fr;
    padding: 10px;
    gap: 15px;
  }

  .searchInput {
    width: 200px;
    padding: 12px 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    font-size: 16px;
    outline: none;
    transition: border-color 0.3s;
  }

  .appContent {
    padding: 12px;
  }

  .appTitle {
    font-size: 16px;
  }

  .appDescription {
    font-size: 13px;
  }

  .appIcon {
    width: 50px;
    height: 50px;
  }
} 