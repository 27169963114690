.layout {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.main {
  flex: 1;
  margin: 0 auto;
  width: 100%;
}

@media (max-width: 768px) {
} 