.heroContainer {
    position: relative;
    width: 100%;
    height: 600px; /* 或您想要的高度 */
    overflow: hidden;
  }
  
  .heroSlider {
    display: flex;
    width: 100%;
    height: 100%;
    transition: transform 0.5s ease-in-out;
  }
  
  .heroSlide {
    position: relative;
    flex: 0 0 100%;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    text-align: center;
  }
  
  .hero h1 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
  }
  
  .slideButton {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 15px;
    cursor: pointer;
    z-index: 10;
  }
  
  .prevButton {
    left: 20px;
  }
  
  .nextButton {
    right: 20px;
  }

.features {
  padding: 4rem 2rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  background: #f5f5f5;
}

.feature {
  text-align: center;
  padding: 1rem;
}

.feature img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 1rem;
}

.products {
  padding: 4rem 2rem;
}

.products h2 {
  text-align: center;
  margin-bottom: 2rem;
}

.productGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
}

.product {
  text-align: center;
  padding: 1rem;
  border: 1px solid #eee;
  border-radius: 8px;
  transition: transform 0.3s ease;
}

.product:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(0,0,0,0.1);
}

.product img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 1rem;
}

.product h3 {
  margin: 1rem 0;
}

.product p {
  color: #666;
}

@media (max-width: 768px) {
  .hero {
    padding: 2rem 1rem;
  }
  
  .features {
    grid-template-columns: 1fr;
    gap: 1rem;
    padding: 2rem 0;
  }
}

.heroContent {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  color: white;
  z-index: 1;
}

.heroContent h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.heroContent p {
  font-size: 1.2rem;
}

.floatingBoxesContainer {
  position: absolute;
  bottom: 80px;
  right: 100px;
  display: flex;
  gap: 60px;
  z-index: 10;
}

@media (max-width: 768px) {
  .floatingBoxesContainer {
    right: 10px;
    gap: 10px;
  }
}

.floatingBox {
  width: 150px;
  height: 100px;
  background-size: cover;
  background-position: center;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease;
  display: block;
}

.floatingBox:hover {
  transform: translateY(-5px);
}

.carouselContainer {
  position: relative;
  width: 100%;
  height: calc(100vh - 54.5px);
  overflow: hidden;
}

.heroSlide {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.topText {
  position: absolute;
  top: 8px;
  right: 15px;
  color: white;
  font-size: 24px;
  font-weight: 900;
}

.bottomText {
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #000000;
  color: #ffffff;
  opacity: 0.5;
}

.socialIcons {
  position: fixed;
  right: 20px;
  top: 30%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  gap: 5px;
  z-index: 1000;
}

.socialIcon {
  width: 40px;
  height: 40px;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease;
}

.socialIcon:hover {
  transform: scale(1.1);
}

.socialIcon img {
  width: 24px;
  height: 24px;
}

@font-face {
  font-family: 'CustomFont';
  src: url('../../assets/MaoKenZhuYuanTi-MaokenZhuyuanTi-2.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
} 