.carousel {
  width: 100%;
  overflow: hidden;
  position: relative;
}

.carouselInner {
  display: flex;
  width: 100%;
}

.carouselItem {
  min-width: 100%;
  flex: 0 0 100%;
}

.carouselButton {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.3);
  color: white;
  border: none;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
  z-index: 10;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s;
}

.carouselButton:hover {
  background: rgba(0, 0, 0, 0.6);
}

.prevButton {
  left: 20px;
}

.nextButton {
  right: 20px;
}