.container {
  padding: 1rem;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.title {
  text-align: center;
  margin-bottom: 2rem;
  color: #333;
}

.inputSection {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 2rem;
  width: 100%;
}

.promptInput {
  width: 100%;
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 1rem;
  resize: vertical;
  box-sizing: border-box;
}

.generateButton {
  padding: 1rem 2rem;
  background-color: #1a73e8;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.generateButton:hover {
  background-color: #1557b0;
}

.generateButton:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.loadingSection {
  text-align: center;
  margin: 2rem 0;
}

.spinner {
  width: 40px;
  height: 40px;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #1a73e8;
  border-radius: 50%;
  margin: 0 auto 1rem;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.resultSection {
  margin-top: 2rem;
  text-align: center;
}

.generatedImage {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.uploadSection {
  margin-bottom: 1rem;
  width: 100%;
}

.fileInput {
  margin-bottom: 1rem;
  width: 100%;
}

.previewContainer {
  margin-bottom: 1rem;
  width: 100%;
  display: flex;
  justify-content: center;
}

.previewImage {
  max-width: 100%;
  max-height: 300px;
  object-fit: contain;
}

/* 移动端适配 */
@media screen and (max-width: 768px) {
  .container {
    padding: 0.5rem;
  }
  
  .previewImage {
    max-height: 200px;
  }
  
  .promptInput {
    min-height: 100px;
  }
}

@media screen and (max-width: 480px) {
  .container {
    padding: 0.25rem;
  }
  
  .previewImage {
    max-height: 150px;
  }
}