.header {
  background: #000000;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  position: sticky;
  top: 0;
  z-index: 100;
}

.container {
  position: relative;
  margin: 0 auto;
  padding: 0 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo img {
  height: 40px;
}

.nav {
  display: flex;
}

.nav a {
  text-decoration: none;
  color: #ffffff;
  font-weight: 500;
  transition: color 0.3s;
}

.nav a:hover {
  color: #007bff;
}

.menuButton {
  display: none;
  flex-direction: column;
  justify-content: space-around;
  width: 30px;
  height: 25px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;
  position: relative;
}

.menuButton:focus {
  outline: none;
}

.menuButton span {
  width: 30px;
  height: 2px;
  background: #fff;
  border-radius: 10px;
  transition: all 0.3s ease-in-out;
  position: absolute;
  left: 0;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}

.menuButton span:first-child {
  top: 0;
}

.menuButton span:nth-child(2) {
  top: 50%;
  transform: translateY(-50%);
}

.menuButton span:last-child {
  bottom: 0;
}

/* 添加动画效果 */
.menuButton.active span:first-child {
  transform: rotate(45deg);
  top: 50%;
}

.menuButton.active span:nth-child(2) {
  opacity: 0;
}

.menuButton.active span:last-child {
  transform: rotate(-45deg);
  bottom: 40%;
}

.rightSection {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  min-width: 0;
  height: 100%;
  align-items: center;
}

.profileIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #333;
  transition: color 0.3s ease;
  padding: 8px;
  border-radius: 50%;
}

.profileIcon:hover {
  color: #1890ff;
  background-color: rgba(24, 144, 255, 0.1);
}

@media (max-width: 768px) {
  .menuButton {
    display: flex;
  }

  .nav {
    display: none;
    position: absolute;
    top: 100%;
    right: 0;
    width: 30%;
    flex-direction: column;
    background: #000000;
    box-shadow: 0 4px 8px rgba(0,0,0,0.2);
    border-radius: 8px;
  }

  .nav.active {
    display: flex;
  }

  .nav a {
    color: #ffffff;
    border-bottom: 1px solid rgba(255,255,255,0.1);
    width: 100%;
    text-align: center;
  }

  .nav a:last-child {
    border-bottom: none;
  }

  .navLink {
    margin: 0;
    height: 40px;
    justify-content: center;
  }

  .navLink img {
    height: 30px;
  }

  .container {
    padding: 0.8rem 1.2rem;
  }
}

:global(.custom-swal-popup) {
  border-radius: 15px;
  padding: 20px;
}

:global(.custom-swal-title) {
  font-size: 24px;
  color: #333;
}

:global(.swal2-confirm) {
  padding: 8px 25px !important;
  font-size: 16px !important;
}

.navLink {
  height: 60px;
  display: flex;
  align-items: center;
  font-family: 'CustomFont', sans-serif;
  padding: 0 5px;
}

.navLink img {
  height: 100%;
}

@media screen and (max-width: 768px) {
  .navLink {
    height: 40px;
  }
  .navLink img {
    height: 100%;
  }
} 

@font-face {
  font-family: 'CustomFont';
  src: url('../../assets/MaoKenZhuYuanTi-MaokenZhuyuanTi-2.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
} 