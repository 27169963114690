.footer {
  background-color: #f8f9fa;
  padding: 3rem 0;
  margin-top: auto;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

.content {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  margin-bottom: 2rem;
}

.section h4 {
  margin-bottom: 1rem;
  font-size: 1.2rem;
  color: #333;
}

.section p {
  margin: 0.5rem 0;
  color: #666;
}

.copyright {
  text-align: center;
  padding-top: 2rem;
  border-top: 1px solid #ddd;
  color: #666;
} 