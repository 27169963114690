.container {
    max-width: 800px;
    margin: 0 auto;
    padding: 2rem;
  }
  
  .header {
    margin-bottom: 2rem;
    text-align: center;
  }
  
  .title {
    font-size: 2rem;
    color: #333;
    margin-bottom: 1rem;
  }
  
  .description {
    color: #666;
    line-height: 1.6;
  }
  
  .form {
    background: #fff;
    padding: 2rem;
    border-radius: 12px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  
  .formField {
    margin-bottom: 1.5rem;
  }
  
  .label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: 500;
    color: #333;
  }
  
  .required {
    color: #ff4d4f;
    margin-left: 4px;
  }
  
  .input {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #d9d9d9;
    border-radius: 6px;
    font-size: 1rem;
    transition: all 0.3s;
  }
  
  .input:focus {
    outline: none;
    border-color: #1890ff;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }
  
  .charCount {
    text-align: right;
    color: #999;
    font-size: 0.875rem;
    margin-top: 0.25rem;
  }
  
  .submitButton {
    width: 100%;
    padding: 0.75rem;
    background: #1890ff;
    color: white;
    border: none;
    border-radius: 6px;
    font-size: 1rem;
    cursor: pointer;
    transition: all 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
  }
  
  .submitButton:hover:not(:disabled) {
    background: #40a9ff;
  }
  
  .submitButton:disabled {
    background: #d9d9d9;
    cursor: not-allowed;
  }
  
  .spinner {
    width: 20px;
    height: 20px;
    border: 2px solid #ffffff;
    border-top: 2px solid transparent;
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  .result {
    margin-top: 2rem;
    padding: 1.5rem;
    background: #f8f9fa;
    border-radius: 12px;
  }
  
  .resultTitle {
    font-size: 1.25rem;
    color: #333;
    margin-bottom: 1rem;
  }
  
  .resultContent {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 20px;
  }
  
  .resultItem {
    border: 1px solid #eee;
    padding: 15px;
    border-radius: 8px;
  }
  
  .resultItemTitle {
    margin-bottom: 10px;
    font-size: 16px;
    color: #333;
  }
  
  .resultImage {
    max-width: 100%;
    height: auto;
    border-radius: 4px;
  }
  
  .resultVideo {
    width: 100%;
    max-width: 600px;
    border-radius: 4px;
  }
  
  .resultText {
    line-height: 1.6;
    color: #333;
    white-space: pre-wrap;
  }
  
  .imageField {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .imagePreview {
    max-width: 200px;
    max-height: 200px;
    object-fit: contain;
  }

  .separator {
    margin: 0 8px;
  }