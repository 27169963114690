.ordersContainer {
  padding: 24px;
  margin: 0 auto;
}

.title {
  margin-bottom: 24px;
  color: #333;
  font-size: 24px;
  font-family: 'CustomFont', sans-serif;
}

/* 响应式设计 */
@media (max-width: 768px) {
  .ordersContainer {
    padding: 16px;
  }
  
  .title {
    font-size: 20px;
    margin-bottom: 16px;
    font-family: 'CustomFont', sans-serif;
  }
}

@font-face {
  font-family: 'CustomFont';
  src: url('../../assets/MaoKenZhuYuanTi-MaokenZhuyuanTi-2.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}