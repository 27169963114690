.container {
  display: flex;
  flex-direction: column;
  padding: 3rem 5rem;
}

.pageHeader {
  width: 100%;
}

.mainContent {
  display: flex;
  gap: 2rem;
}

/* 左侧部分 */
.leftSection {
  flex: 1;
}

.mainImage {
  width: 100%;
  aspect-ratio: 16/9;
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mainImage img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  width: auto;  /* 让图片保持原始宽高比 */
  height: auto;  /* 让图片保持原始宽高比 */
}

.thumbnails {
  display: flex;
  gap: 10px;
  overflow-x: auto;
}

.thumbnail {
  width: 80px;
  height: 80px;
  border: 2px solid transparent;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  position: relative;
}

.thumbnail.active {
  border-color: #fdb3ee;
  position: relative;
}

.thumbnail.active::before {
  content: '';
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #ff4d4f;
  z-index: 1;
}

.thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* 右侧部分 */
.rightSection {
  flex: 1;
}

.viewImages {
  display: flex;
  justify-content: space-between;
}

.viewImage {
  flex: 1;
  text-align: center;
}

.viewImage img {
  width: 100%;
  height: 150px;
  object-fit: contain;
  margin-bottom: 8px;
}

.viewImage span {
  font-size: 14px;
  color: #666;
}

.productInfo {
  background: #eee9ff;
  padding-top: 10px;
}

.title {
  font-size: 20px;
  margin: 0 0 16px 0;
  color: #685aae;
}

.description {
  color: #685aae;
  margin-bottom: 20px;
  line-height: 1.6;
}

.specifications {
  display: grid;
  gap: 10px;
}

.specItem {
  display: flex;
  align-items: center;
}

.label {
  color: #685aae;
  margin-right: 8px;
  min-width: 60px;
}

.groupInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #cfb8fe;
  margin-top: 20px;
}

.groupInfoItem {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: relative;
}

.groupInfoItem:not(:last-child)::after {
  content: '';
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 70%;
  width: 1px;
  background-color: rgba(255, 255, 255, 0.1);
}

.groupInfoItem .label {
  color: #fff;
  margin-bottom: 8px;
}

.groupInfoItem .count,
.groupInfoItem .price {
  color: white;
  font-size: 1.2em;
  font-weight: bold;
}

.joinButton {
  padding: 10px 30px;
  background: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background 0.3s;
}

.joinButton:hover {
  background: #0056b3;
}

.actionSection {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 16px;
  background: #fff;
  box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
}

.groupButton {
  width: 90%;
  max-width: 400px;
  height: 44px;
  background: #ff4d4f;
  color: #fff;
  border: none;
  border-radius: 22px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
}

.groupButton:hover {
  background: #ff7875;
}

.detailSection,
.rulesSection {
  background: #fff;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
}

.sectionTitle {
  font-size: 18px;
  font-weight: bold;
  margin: 0 0 16px 0;
  padding-bottom: 12px;
  border-bottom: 1px solid #f0f0f0;
}

.details,
.rules {
  font-size: 14px;
  color: #666;
  line-height: 1.6;
} 

.darkBackground {
  background-color: #eee9ff;
}

.primaryText {
  color: #FFD700; /* 金色 */
  font-size: 20px;
}

.secondaryText {
  color: #808080; /* 灰色 */
  font-size: 20px;
}

.separator {
  color: #808080;
  margin: 0 10px;
  font-size: 20px;
}

.groupTip {
  color: #685aae;
  font-size: 12px;
}

.editButtonContainer {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.editButton {
  align-items: center;
  gap: 5px;
  padding: 8px 16px;
  background: #fdb3ee;
  border: none;
  color: white;
  cursor: pointer;
  transition: all 0.3s ease;
  clip-path: polygon(85% 0, 100% 50%, 85% 100%, 0 100%, 15% 50%, 0 0);
  padding-left: 24px;
  padding-right: 24px;
}


.pencilIcon {
  font-size: 16px;
}

@font-face {
  font-family: 'CustomFont';
  src: url('../../assets/MaoKenZhuYuanTi-MaokenZhuyuanTi-2.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translate(-50%, -20px);
  }
  to {
    opacity: 1;
    transform: translate(-50%, 0);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: translate(-50%, 0);
  }
  to {
    opacity: 0;
    transform: translate(-50%, -20px);
  }
}

@media screen and (max-width: 768px) {
  .container {
    padding: 1rem;
  }

  .mainContent {
    flex-direction: column;
    gap: 1rem;
  }

  .leftSection, 
  .rightSection {
    width: 100%;
  }

  .mainImage {
    width: 100%;
    aspect-ratio: 16/9;
    background-color: #f5f5f5;
  }

  .viewImages {
    height: auto !important;
    aspect-ratio: 16/9;
    background-color: #f5f5f5;
  }
}