@font-face {
  font-family: 'CustomFont';
  src: url('../../assets/MaoKenZhuYuanTi-MaokenZhuyuanTi-2.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 400px;
  position: relative;
  font-family: 'CustomFont', sans-serif;
}

.title {
  text-align: center;
  margin-bottom: 20px;
  font-family: 'CustomFont', sans-serif;
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.inputGroup {
  margin-bottom: 15px;
}

.inputGroup label {
  font-family: 'CustomFont', sans-serif;
}

.input, .codeInput {
  width: 100%;
  padding: 8px;
  margin-top: 5px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.submitButton {
    margin-top: 20px;
    font-family: 'CustomFont', sans-serif !important;
}